import * as React from "react";

import * as ReactBootstrap from "react-bootstrap";

import "./Modal.scss";



interface ModalProps extends ReactBootstrap.ModalProps {
  gradiant?: boolean;
  dataAos?: string;
  dataAosOffset?: string;
  dataAosDelay?: string;
}

const Modal = (
  {
    children,
    className,
    show,
    size,
    fullscreen,
    centered,
    scrollable,
    onShow,
    onHide,
    gradiant = false,
    dataAos,
    dataAosOffset = '0',
    dataAosDelay = '0'
  }: ModalProps
) => {
  return (
    <ReactBootstrap.Modal
      className={(className ? className : '') + (gradiant ? ' modal-gradiant' : '')}
      show={show}
      size={size}
      fullscreen={fullscreen}
      centered={centered}
      scrollable={scrollable}
      onShow={onShow}
      onHide={onHide}
      // onShow={onShow}
      // onHide={onHide}
      data-aos={dataAos}
      data-aos-offset={dataAosOffset}
      data-aos-delay={dataAosDelay}
    >
      {children}
    </ReactBootstrap.Modal>
  );
};

export default Modal;
