import * as React from "react";
import {useEffect} from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import * as ReactBootstrap from "react-bootstrap";

import {
  CoachPlayer,
  CoachPlayerAction,
  getCoachPlayers,
  setCoachPlayersToState,
} from "../../../../store/coach/player-list/CoachPlayerList.slice";
import {
  getCoachPlayersFromDatabase,
} from "../../../../utils/coach/player-utils";

import Button from "../../../BaseComponent/ButtonComponent/Button/Button";
import Dropdown from "../../../BaseComponent/DropdownComponent/Dropdown/Dropdown";
import DropdownMenu from "../../../BaseComponent/DropdownComponent/Dropdown/Dropdown/DropdownMenu";
import DropdownItem from "../../../BaseComponent/DropdownComponent/Dropdown/Dropdown/Menu/DropdownItem";
import ListGroup from "../../../BaseComponent/ListGroupComponent/ListGroup/ListGroup";

import "./CoachPlayerList.scss";



interface CoachPlayerListProps {
  displayPlayerProfileModal: (player: CoachPlayer|null) => void
}

const CoachPlayerList = ({displayPlayerProfileModal}: CoachPlayerListProps) => {
  const dispatch = useAppDispatch();

  const coachPlayersFromDb = useAppSelector(getCoachPlayersFromDatabase)
  const coachPlayers = useAppSelector(getCoachPlayers)

  const onPlayerActionClick = (action: CoachPlayerAction, player: CoachPlayer) => {
    if (action.code === 'seeProfile') {
      displayPlayerProfileModal(player)
    }
  }

  useEffect(() => {
    dispatch(setCoachPlayersToState(coachPlayersFromDb))
  }, [])

  return (
    <div className={'coach-player-list-component'}>

      <div className={'coach-player-list-header'}>
        <div className={'coach-player-list-header-left'}>
          <div className={'coach-player-list-title-container'}>
            <h2 className={'coach-player-list-title'}>Joueurs</h2>
          </div>

          <div className={'coach-player-list-search'}>
            <ReactBootstrap.InputGroup className="coach-player-list-search-form-group">
              <ReactBootstrap.Form.Control
                className={'coach-player-list-search-form-control'}
                placeholder="Recherche"
                aria-label="Recherche"
              />
              <Button
                className={'btn-rounded'}
                variant={'primary'}
                gradiant={true}
                icon={faSearch}
              ></Button>
            </ReactBootstrap.InputGroup>
          </div>
        </div>

        <div className={'coach-player-list-header-right'}>
          <div className={'coach-player-list-main-actions'}>
            <Button
              variant={'primary'}
              size={'lg'}
              className={'btn-rounded'}
              gradiant={true}
              icon={faPlus}
              dataAos="fade-left"
            >Ajouter Joueur</Button>
          </div>
        </div>
      </div>

      <div className={'coach-player-list-container'}>
        <ListGroup
          header={{items: [
              {
                code: 'character_name',
                label: 'Nom Personnage',
                size: {
                  md: 3,
                },
              },
              {
                code: 'name',
                label: 'Nom',
                size: {
                  md: 3,
                },
              },
              {
                code: 'progression',
                label: 'Progression',
                align: 'center',
                size: {
                  md: 2,
                },
              },
              {
                code: 'successful_weeks',
                label: 'Semaines Réussies',
                align: 'center',
                size: {
                  md: 2,
                },
              },
              {
                code: 'actions',
                label: 'Actions',
                align: 'end',
                size: {
                  md: 2,
                },
              },
            ]}}
        >
          {coachPlayers.map((player, playerIndex) => {
            return (
              <ReactBootstrap.ListGroup.Item
                key={playerIndex + 1}
                className={'list-group-item'}>
                <ReactBootstrap.Row
                  className={'g-0 list-group-item-container'}
                >

                  <ReactBootstrap.Col
                    md={3}
                  >
                    <div className={'list-group-item-avatar-pseudo'}>
                      <div className={'list-group-item-avatar'}>
                        <img className={'list-group-item-avatar-image'}
                             src={player.avatar}
                             alt={'Player avatar'}
                        />
                      </div>
                      <div className={'list-group-item-pseudo'}>{player.pseudo}</div>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={3}
                  >
                    <div className={'list-group-item-full-name'}>
                      <span>{ player.fullName }</span>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={2}
                  >
                    <div className={'list-group-item-progress text-center'}>
                      <span></span>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={2}
                  >
                    <div className={'list-group-item-successful-weeks text-center'}>
                      <span>{ player.successfulWeeks }</span>
                    </div>
                  </ReactBootstrap.Col>

                  <ReactBootstrap.Col
                    md={2}
                  >
                    <div className={'list-group-item-actions text-end'}>
                      <Dropdown>
                        <ReactBootstrap.Dropdown.Toggle
                          variant={'primary'}
                          size={'lg'}
                          className={'btn-rounded'}
                          // gradiant={true}
                          // dataAos="fade-left"
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                          />
                        </ReactBootstrap.Dropdown.Toggle>
                        <DropdownMenu>
                          {player.actions.map((action, actionIndex) => (
                            <DropdownItem
                              key={actionIndex}
                              href={'#/' + action.code}
                              icon={action.icon}
                              onClick={() => onPlayerActionClick(action, player)}
                            >
                              { action.label }
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </ReactBootstrap.Col>

                </ReactBootstrap.Row>
              </ReactBootstrap.ListGroup.Item>
            )
          })}
        </ListGroup>
      </div>
    </div>
  );
};

export default CoachPlayerList;
