import * as React from "react";

import {useAppDispatch, useAppSelector} from "../../../../store/hooks";

import * as ReactBootstrap from "react-bootstrap";

import Modal from "../../../BaseComponent/ModalComponent/Modal/Modal";
import {
  getCoachPlayerProfileModalPlayer, setCoachPlayerProfileModalPlayer
} from "../../../../store/coach/player-profile-modal/CoachPlayerProfileModal.slice";

import "./PlayerProfileModal.scss";



interface PlayerProfileModalProps {
}

const PlayerProfileModal = ({}: PlayerProfileModalProps) => {
  const dispatch = useAppDispatch();

  const player = useAppSelector(getCoachPlayerProfileModalPlayer)
  const show = player !== null

  const handleClose = () => {
    dispatch(setCoachPlayerProfileModalPlayer(null))
  }

  return (
    <Modal
      className={'player-profile-modal'}
      show={show}
      gradiant={true}
      centered={true}
      scrollable={true}
      size={'lg'}
      onHide={handleClose}
      // backdrop="static"
      // keyboard={false}
    >
      <ReactBootstrap.Modal.Header closeButton>
        <ReactBootstrap.Modal.Title>Profile du joueur</ReactBootstrap.Modal.Title>
      </ReactBootstrap.Modal.Header>
      <ReactBootstrap.Modal.Body>
        {player &&
          <div className={'player-profile-avatar-pseudo row'}>
            <div className={'player-profile-avatar col-4 col-md-2'}>
              <img className={'player-profile-avatar-image'}
                   src={player.avatar}
                   alt={'Player avatar'}
              />
            </div>
            <div className={'player-profile-pseudo col-8 col-md-10'}>
              <div>
                <span className={'pe-2'}>Pseudo:</span>
                <span>{player.pseudo}</span>
              </div>
              <div>
                <span className={'pe-2'}>Nom:</span>
                <span>{player.fullName}</span>
              </div>
              <div>
                <span className={'pe-2'}>Semaines Réussies:</span>
                <span>{player.successfulWeeks}</span>
              </div>
              <div>
                <span className={'pe-2'}>Progression:</span>
                <span></span>
              </div>
            </div>
          </div>
        }
      </ReactBootstrap.Modal.Body>
      {/*<ReactBootstrap.Modal.Footer>*/}
      {/*  Footer*/}
      {/*</ReactBootstrap.Modal.Footer>*/}
    </Modal>
  )
}

export default PlayerProfileModal
