import * as React from "react";
import * as ReactBootstrap from "react-bootstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";

interface DropdownItemProps extends ReactBootstrap.DropdownItemProps {
  icon?: IconDefinition;
}

const DropdownItem = (
  {
    children,
    className,
    href,
    icon,
    onClick,
  }: DropdownItemProps
) => {
  return (
    <ReactBootstrap.Dropdown.Item
      href={href}
      className={className}
      onClick={onClick}
    >
      {icon &&
        <FontAwesomeIcon
          icon={icon}
        />
      }
      {children}
    </ReactBootstrap.Dropdown.Item>
  );
};

export default DropdownItem;
